<template>
  <li class="treeitem2" :class="{folder: isFolder}">
    <div
      :class="{folder: isFolder, opened: isOpen, selected: selectedItem == item || (selectedItem.condition && selectedItem.parent == item), newer: newer}"
      :style="{paddingLeft: (isFolder ? 25 : 0) + (level*25)+'px', backgroundPositionX: (level*25)+'px'}"
      :title="newer ? newer : ''"
      @click="toggle">
      
      <div class="icon" v-if="isFolder" :style="{left: level*25+'px'}"></div>
      <div v-if="!isFolder" class="thumbnail">        
        <img v-if="!isFolder" :src="contentRoot + item.icon" />
        <div v-if="!isFolder" class="shadow"></div>
        <img v-if="played" class="played" src="../assets/approved.svg" />
      </div>
      <div class="title">{{ editorMode ? (item.type == 'm' ? item.name : (parsedStructure ? parsedStructure.txt1 : '???')) : item.txt1 }}</div>
    </div>
    <ul v-show="isOpen" v-if="isFolder" class="tree">
      <div v-if="isFolder" :style="{left: (level*25 + 6)+'px'}"></div>
      <TreeItem2        
        v-for="(child, index) in (editorMode ? item.children : item.list)"
        :editorMode="editorMode"
        :publishDate="publishDate"
        @select="selectHandler"
        @opened="openedHandler"
        :key="(editorMode ? (`${level}_${index}_${item.id}`) : `${level}_${index}`)"
        :item="child"
        :selectedItem="selectedItem"
        :parent = item
        :level="level + 1"
        :contentRoot="contentRoot"
        :metadata="metadata"
        :expandAll="expandAll"
      ></TreeItem2>      
    </ul>
  </li>
</template>



<script>

export default {
  name: 'TreeItem2',
  props: {
    item: Object,
    level: Number,
    selectedItem: Object,
    parent: Object,
    metadata: Object,
    editorMode: Boolean,  // true when used for authoring, where we deal with content blocks with embedded structure. false when fed by the json structure directly.
    publishDate: String,
    contentRoot: String,
    expandAll: Boolean
  },
  data: function() {
    return {
      isOpen: false,
      //selected: false
    };
  },
  computed: {
    played(){
      return !this.isFolder 
      && this.metadata?.playedSegmentIds
      && 
      ( 
        (this.item.type == 'seg' && this.metadata.playedSegmentIds.includes(this.item.id)) 
        || 
        (this.item.type == 'conditional' && this.item.list.find(s => s.type == 'seg' && this.metadata.playedSegmentIds.includes(s.id))) 
      )
    },
    isFolder: function() {
      return this.editorMode
      ? this.item.type == 'm' || this.item.type == 'f'
      : this.item.type == 'list' && this.item.list && this.item.list.length;
    },
    parsedStructure(){
      return this.editorMode ? JSON.parse(this.item.structure) : null
    },
    newer(){
      //if(this.editorMode && (this.item.published > this.publishDate /*|| !this.publishDate*/)){
      if(this.publishDate && this.item.published && (this.item.published > this.publishDate)){
        const itemPublished = new Date(this.item.published)
        const diffTime = Math.abs(new Date() - itemPublished);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        return `Newer version exists since ${itemPublished.toLocaleString()} (${diffDays} days ago)`
      }
      return null
    }
  },
  methods: {
    toggle: function() {
      if (this.isFolder) {
        //if(!(this.isOpen && this.item != this.selectedItem) || this.editorMode)
          this.isOpen = !this.isOpen;
      }
      // this.selected = true
      // console.log(this.selected)
      else
        this.$emit("select", this.item)
    },
    selectHandler(event){  
      this.$emit('select', event)
    },    
    openedHandler(){
      this.isOpen = true;
      this.$emit('opened')
    }
    // makeFolder: function() {
    //   if (!this.isFolder) {
    //     this.$emit("make-folder", this.item);
    //     this.isOpen = true;
    //   }
    // }
  },
  watch: {
    // selectedItem: function(newVal, oldVal) { // watch it
    //     console.log('Prop changed: ', newVal, ' | was: ', oldVal)
    //   }
    selectedItem(newItem) {
      if(/*this.isFolder &&*/ this.item == newItem){
        this.isOpen = true
        this.$emit('opened')
        // console.log(newItem.txt1)
      }
    },
    expandAll(newItem){
      this.isOpen = newItem
    }
  },
  mounted(){
    if(this.expandAll)
      this.isOpen = true
  }
}
</script>



<style lang="scss">
ul.tree{
  list-style-type: none;
  padding: 0;
  position: relative;
}

.treeitem2{
  min-height: 32px;  

  --thumbnail-width: 110px;
  --thumnail-height: 68px;

  .thumbnail{
    position: relative;
    width: var(--thumbnail-width);  
    height: var(--thumnail-height);
    margin-top: 5px;
    border-radius: 4px;
    box-sizing: border-box;

    img{      
      width: 100%;
      height: 100%;
      border-radius: 4px;
      box-sizing: border-box;           
      object-fit: cover;
    }

    img.played{
      position: absolute;
      right: 6px;
      bottom: 6px;
      width:18px;
      height:18px;
    }

    .shadow{
      display: none;
    }    
  }

  &:not(.folder){
    &:hover{      
      .shadow{        
        box-shadow: inset 0 0 0 3px #86C596;   
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
        border-radius: 4px;
      }         
    }
  }

  > div {
    padding: 3px 0;
    cursor: pointer;

    .title{
      line-height: 1.2;
      max-width: var(--thumbnail-width);
      margin-top: 3px;
    }

    &.selected{
      background-color: #EFF2F4;    

      .thumbnail img:not(.played){
        opacity: 1;
        border: solid 3px #4cad82;
      }
    }

    &:hover{
      background-color: #EFF2F4;
      &:active{
        background-color: #E9F4EA;
      }
    }

    &.newer{
      color: $red;
    }    
  }

  &.folder{
    ul.tree > div {
      position: absolute;
      width: 1px;
      height: 95%;
      background-color: #e3e3e3;
      z-index: 10;
      pointer-events: none;
    }
  }

  .folder{         
    min-height: 32px;
    position: relative;

    .icon{
      position: absolute;
      width: 1.5em;
      height: 1.5em;
      top: 0.35em;
      background-size: contain;
      background-image: url("../assets/folder_close.svg");
      background-repeat: no-repeat;
    }

    > .title{      
      line-height: 1.3;    
      padding-left: 0.1em;  
    }

    &.opened{
      .icon{
        background-image: url("../assets/folder_open.svg");
      }      
      margin-bottom: 0;   
      
      > .title{
        font-weight: bold;
      }
    }
    
  }

  &:not(.folder) + .folder{
    margin-top: 5px;
  }

  @media (min-width: 1440px){ 
    --thumbnail-width: 123px;
    --thumnail-height: 75px;
  }  

  @media (min-width: 1600px){ 
    --thumbnail-width: 136px;
    --thumnail-height: 83px;

    .folder > .title{
      padding-left: 0.4em;
    }
  }  

  @media (min-width: 1920px){ 
    --thumbnail-width: 162px;
    --thumnail-height: 100px;
  }  
}
</style>
